import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  computed,
  inject,
  signal,
  input,
  effect,
  model,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  Maybe,
  Media,
  MediaForMediaListFragment,
  User,
  FolderGroupType,
  SaveMediaInput,
  MediaBulkAction,
  MediaVisibilityType,
  MediaFiltersInput,
} from '@designage/gql';
import {
  NgbDropdownModule,
  NgbModal,
  NgbPopoverModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { DatatableRowActivationType, CeCalledFrom } from '@desquare/enums';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  SlidePanelService,
  FolderService,
  MediaService,
  SessionService,
  PlaylistEditorService,
} from '@desquare/services';
import { CloudinaryService } from '@desquare/services';
import {
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
  IDesignageDataTableColumns,
  IMediaFilterButtonGroupOutput,
  IMediaForm,
  IMediaForMediaList,
  IMediaVisibilityInfo,
} from '@desquare/interfaces';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '@desquare/environments';
import {
  bulkActionsUtil,
  dragDropCursorUtil,
  getRandomString,
  stringToEnumUtil,
} from '@desquare/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { domConstants } from '@desquare/constants';
import { styleUtil } from '@desquare/utils';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { CreativeEditorComponent } from '@designage/app/creative-editor/creative-editor/creative-editor.component';
import { MoveMediaFolderDialogComponent } from '@desquare/components/common/src/modals/move-media-folder-dialog.component';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SearchInputComponent } from '@desquare/components/common/src/search-input/search-input.component';
import { MediaFilterButtonGroupComponent } from '@designage/app/shared/media-filter-button-group/media-filter-button-group.component';
import { AngularSplitModule } from 'angular-split';
import { FolderExplorerComponent } from '@desquare/components/common/src/folder/folder-explorer/folder-explorer.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { CloudinaryModule } from '@cloudinary/ng';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { FileSizePipe } from '@desquare/components/common/src/pipe/file-size/file-size.pipe';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { MediaStore } from '@desquare/stores';
import { MediaGridCardComponent } from './media-grid-card.component';
import { NgClass, SlicePipe } from '@angular/common';
import {
  CdkDragDrop,
  CdkDragStart,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { time } from 'console';
import { ThumbnailPreviewDialogComponent } from '@designage/app/playlist-sequence/thumbnail-preview-dialog/thumbnail-preview-dialog.component';

interface IFolderBreadCrumb {
  id: string | null;
  name: string;
}

const DEFAULT_FOLDER_PATH = 'root/';

@Component({
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    DragDropModule,
    TranslateModule,
    AngularSplitModule,
    CloudinaryModule,
    NgbDropdownModule,
    NgbTooltip,
    NgbPopoverModule,
    MatButtonModule,
    MatMenuModule,
    LoaderComponent,
    DesignageDataTableComponent,
    SearchInputComponent,
    MediaFilterButtonGroupComponent,
    FolderExplorerComponent,
    TableDateTimeComponent,
    RouterOutlet,
    FileSizePipe,
    CloudinaryMediaComponent,
    MediaGridCardComponent,
    SlicePipe,
  ],
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FolderService, MediaService], // note: this is not sharing the same instance with the <app-folder-tree>
})
export class MediaListComponent implements OnInit, OnDestroy {
  mediaStore = inject(MediaStore);
  playlistEditorService = inject(PlaylistEditorService);

  subs = new SubSink();
  @ViewChild(DesignageDataTableComponent)
  designageDataTable!: DesignageDataTableComponent;

  user = input<User | undefined>(undefined);
  galleryView = input<boolean>(false);
  showHeader = input<boolean>(true);
  headerText = input<string>('CONTENT_CONNECTED_TO_THIS_PROFILE');
  showFolderExplorerText = input<boolean>(true);
  folderExplorerWidth = input<number>(250);
  @Input() isAssetSelection!: boolean;
  @Input() enableCheckbox = true;
  @Input() showBulkAction = true;
  @Input() showCreativeEditor = true;
  @Input() enableSlidePanel = true;
  @Output() selectedMediaList = new EventEmitter<Media[]>();
  @Output() selectedMedia = new EventEmitter<Media>();
  selectedMediaItems: Media[] = [];

  slidePanelService = inject(SlidePanelService);

  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedMediaId = this.slidePanelService.getPanelComponentId();

  filteredMedia = this.mediaStore.filteredMedia;
  loaderMessage!: string;
  loading = false;
  desColumns: IDesignageDataTableColumns[] = [];
  medias: IMediaForMediaList[] = [];
  // mediaList: Media[] = [];
  mediaSearchForm!: FormGroup;
  mediaActionsForm!: FormGroup;
  viewModeFormGroup!: FormGroup;
  datatableLoading = this.mediaStore.loading;
  media!: Maybe<Media>;
  isUploadDialogOpen = false;
  total = 0;
  page = 1;
  pageSize = 10;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
  mediaListActions = Object.values(MediaBulkAction);
  profileId!: Maybe<string>;
  prevPage = 1;
  showReloadButton = false;
  externalPaging = false;
  hasNoData = computed(() => this.mediaStore.filteredMedia().length === 0);

  bulkActionsUtil = bulkActionsUtil;

  getUserMediasSubscription?: Subscription;
  getProfileMediasSubscription?: Subscription;

  folderExplorer = signal<boolean>(true);

  /** limit to only images or only videos */
  @Input()
  filterMediaTypes = '';

  @Input()
  multiSelect = true;

  // slide panel variables
  visibilityModes: IMediaVisibilityInfo[] = [];

  globalSearch = this.mediaStore.globalSearch;

  get showVisibilityModes() {
    return this.visibilityModes.length > 1;
  }

  selectedFolderId = this.mediaStore.selectedFolderId;
  getSelectedFolderId = computed(() => this.selectedFolderId());
  selectedFolderId$ = toObservable(this.selectedFolderId);

  selectedFolderFullPath: string = DEFAULT_FOLDER_PATH;
  folderBreadCrumbs: IFolderBreadCrumb[] = [];

  filteredMediaList$!: Observable<IMediaForMediaList[]>;
  mediaFilterButtonGroupOutput = signal<IMediaFilterButtonGroupOutput[]>([]);

  folderTreeIds = computed(() => this.mediaService.folderIds());
  mediaListSignal = signal<IMediaForMediaList[]>([]);
  mediaListComputed = computed(() => this.mediaListSignal());

  constructor(
    private toasterService: ToasterService,
    private encryptionService: EncryptionService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private currentUserService: CurrentUserService,
    private session: SessionService,
    private translateService: TranslateService,
    private cloudinaryService: CloudinaryService,
    private folderService: FolderService,
    private mediaService: MediaService
  ) {
    effect(() => {
      // console.log('folderId', this.folderTreeIds());
      // console.log('filtered Media', this.mediaStore.filteredMedia());
      // console.log('this.globasSearch', this.mediaStore.globalSearch());
    });
  }

  reloadMedia() {
    this.mediaService.refetchMedia();
  }

  /**
   * gets the sequence (formerly known as asset) ids. (assetIds = sequenceIds)
   */
  get sequenceIds() {
    return this.playlistEditorService.editingSequences.map((asset) => asset.id);
  }

  get showButtons() {
    return this.profileId;
  }

  get bulkActionButtonStyle() {
    return styleUtil.getBulkActionButtonStyle(
      this.mediaActionsForm.controls.action.value
    );
  }

  get bulkActionButtonIsEnabled() {
    const action = this.mediaActionsForm.controls.action.value;

    return this.filteredMedia().length > 0 && action;
  }

  openUploadWidget() {
    this.cloudinaryService.getUploadWidget().open();
  }

  createMedia(mediatype: 'Design' | 'Video' = 'Design') {
    const modalRef = this.modalService.open(CreativeEditorComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });
    modalRef.componentInstance.calledFrom = CeCalledFrom.NEW;
    modalRef.componentInstance.initialSceneMode = mediatype;
    if (this.media) {
      const id = this.encryptionService.encrypt(this.media.id);
    }
  }

  ngOnDestroy() {
    this.cloudinaryService.destroyUploadWidget();
    this.getProfileMediasSubscription?.unsubscribe();
    this.getUserMediasSubscription?.unsubscribe();
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
    this.initForm();
    // this.initObservables();
    this.initSubscriptions();
  }

  async initUploadWidget() {
    /* const folder = this.profileId
      ? `${FolderGroupType.Profile}/${this.profileId}`
      : `${FolderGroupType.User}/${this.user?.id}`;
      */

    const profileId = this.profileId;
    const folder = profileId
      ? `${FolderGroupType.Profile}/${profileId}`
      : `${FolderGroupType.User}/${this.user()!.id}`; // this should never happen!
    const [folderGroupType, idFolder] = folder.split('/');
    const cloudinaryParams = environment.cloudinary;

    let queue = cloudinaryParams.uploadWidgetParameters.text.default.queue;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { title_uploading_with_counter, title, upload_more } = queue;

    queue = {
      title: await lastValueFrom(this.translateService.get(title)),
      title_uploading_with_counter: await lastValueFrom(
        this.translateService.get(title_uploading_with_counter)
      ),
      upload_more: await lastValueFrom(this.translateService.get(upload_more)),
    };

    cloudinaryParams.uploadWidgetParameters.folder = folder;
    cloudinaryParams.uploadWidgetParameters.text.default.queue = queue;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cloudinaryService.createUploadWidget(
      cloudinaryParams,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any, result: any) => {
        if (error) {
          this.toasterService.error('ERROR_CODE_6_5');
          return;
        }

        switch (result.event) {
          case 'success': {
            const {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              public_id,
              url,
              secure_url,
              original_filename,
              resource_type,
              width,
              height,
              bytes,
              created_at,
              format,
              duration,
              frame_rate,
              nb_frames,
            } = result.info;
            const response: SaveMediaInput = {
              folder: idFolder,
              folderGroupType:
                stringToEnumUtil.getFolderGroupType(folderGroupType),
              name: original_filename,
              publicId: public_id,
              resourceType: stringToEnumUtil.getResourceType(resource_type),
              secureUrl: secure_url,
              url,
              dbFolderId: this.getSelectedFolderId(),
              defaultMeta: {
                uploadedAt: created_at,
                width,
                height,
                bytes,
                format,
                duration,
                //frameRate: frame_rate,
                //frame_count: nb_frames
                // video:{
                //   bitRate: bit_rate,
                //   codec,
                //   dar,
                //   level,
                //   pixelFormat: pix_format,
                //   profile,
                //   timeBase: time_base,
                // },
                // audio:{
                //   bitRate:bit_rate,
                //   channelLayout: channel_layout,
                //   channels,
                //   codec,
                //   frequency
                // }
              },
            };
            this.saveMedia(response);
            break;
          }
          case 'queues-start':
            this.toasterService.info('UPLOADING_MEDIA');
            break;
        }
      }
    );
  }

  initForm() {
    this.mediaSearchForm = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
      globalSearch: [false],
    });
    this.mediaActionsForm = this.formBuilder.group({
      action: [null],
    });
    this.viewModeFormGroup = this.formBuilder.group({
      isGridView: this.galleryView() ? true : false,
    });
  }

  initVariables() {
    this.profileId = this.session.profileId();

    this.desColumns = [
      {
        fieldName: 'publicId',
        name: 'THUMBNAIL',
        type: 'template',
        templateRef: 'thumbnail',
        visible: 'mandatory',
        childComponentStyle:
          'justify-content: center !important; height: 8rem;',
        disableSorting: true,
        style: 'max-width: 8rem; min-width: 8rem;',
      },
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
        style: 'min-width: 15rem;',
      },
      {
        fieldName: 'library',
        name: 'TYPE',
        type: 'string',
        visible: 'mandatory',
        style: 'max-width: 7.5rem;',
      },
      {
        fieldName: 'metadata.bytes',
        name: 'FILE_SIZE',
        type: 'template',
        templateRef: 'fileSize',
        visible: 'mandatory',
        style: 'max-width: 7.5rem;',
      },
      {
        fieldName: 'metadata.width',
        name: 'DIMENSIONS',
        type: 'template',
        templateRef: 'dimensions',
        visible: 'mandatory',
        style: 'max-width: 7.5rem;',
      },
      {
        fieldName: 'metadata.createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: 'mandatory',
      },
    ];

    // this.visibilityModes.push({
    //   visibility: MediaVisibilityType.Default,
    //   description: 'MEDIA_VISIBILITY_DEFAULT',
    // });
    // if (this.currentUserService.canEditTemplate) {
    //   this.visibilityModes.push({
    //     visibility: MediaVisibilityType.Template,
    //     description: 'MEDIA_VISIBILITY_TEMPLATE',
    //   });
    // }
  }

  initSubscriptions() {
    if (this.profileId) {
      // TODO: handle case later
      this.initUploadWidget();
    }
  }

  cancelUpload(values: IMediaForm) {
    // TODO: test this
    const mediaIds = values.items.map(({ id }) => id);
    lastValueFrom(this.mediaService.deleteMedia(mediaIds));
  }

  navigateToMedia({ type, row }: IDatatableRowActivateArgs) {
    if (type === DatatableRowActivationType.CLICK) {
      const media: Media = row;
      if (media && media.id) {
        const encryptedMediaId = this.encryptionService.encrypt(media.id);

        if (this.currentUserService.canManageMedias) {
          this.router.navigate(['media/manage', encryptedMediaId]);
        }
      }
    }
  }

  openPreviewDialog(mediaItem: Media) {
    console.log('mediaItem', mediaItem);

    const modalRef = this.modalService.open(ThumbnailPreviewDialogComponent, {
      windowClass: 'custom-centered-modal',
      size: 'lg',
    });

    const assetItem = this.setTemporaryAssetItem(mediaItem);

    modalRef.componentInstance.assetItem.set(assetItem);

    if (assetItem.__typename === 'VideoAsset') {
      this.playlistEditorService.previewPlayToggleTriggered.emit(false);
    }
  }
  setTemporaryAssetItem(mediaItem: Media) {
    const assetItem = {
      id: getRandomString(),
      contentId: mediaItem.id,
      name: mediaItem.name,
      type: this.playlistEditorService.setAssetType(mediaItem.type),
      publicId: mediaItem.publicId,
      campaignEnd: null,
      campaignStart: null,
      days: null,
      duration: null,
      uri: mediaItem.secureUrl,
      sequence: null,
      webpUrl: null,
      mediaItem,
      __typename: this.playlistEditorService.setContentTypeName(mediaItem.type),
    };
    return assetItem;
  }

  onRowClick(media: Media) {
    if (!this.isAssetSelection) {
      this.slidePanelService.setPanelComponentId(media.id);
      const encryptedMediaId = this.encryptionService.encrypt(media.id);

      if (this.currentUserService.canManageMedias) {
        this.router.navigate(['media/manage', encryptedMediaId]);
      }
    } else {
      this.selectedMedia.emit(media);
      this.selectedMediaList.emit(this.selectedMediaItems);

      if (this.multiSelect === false) {
        this.selectedMediaList.emit([media]);
        console.log('selectedMediaList', [media]);
      }
    }
  }
  dragStart(event: CdkDragStart) {
    console.log('dragStart', event);
    dragDropCursorUtil.cursorGrabbing();
  }

  dropTable(event: CdkDragDrop<any[]>) {
    console.log('dropTable', event);

    // this.dataDropped.emit(event);
    dragDropCursorUtil.cursorReset();
  }

  onSelectedChange(rows: Media[]) {
    // this.mediaList = mediaList;
    this.selectedMediaItems = rows;
    this.selectedMediaList.emit(this.selectedMediaItems);
  }

  onPageChange({ page }: IDatatablePageChangeArgs) {
    this.prevPage = this.page;
    this.page = page;

    if (this.externalPaging) {
      if (
        this.page > this.medias.length / this.pageSize &&
        this.medias.length !== this.total
      ) {
        this.mediaService.refetchMedia();
        // this.getMedia();
      }
    }
  }

  applyFilter(filterValue: string) {
    this.mediaStore.setFilter({
      name: filterValue,
      folderId: this.selectedFolderId(),
    });
  }

  async saveMedia(cloudinaryResponse: SaveMediaInput) {
    await lastValueFrom(
      this.mediaService.saveMedia({
        ...cloudinaryResponse,
        dbFolderId: this.getSelectedFolderId(),
      })
    );
  }

  submitBulkAction() {
    const action = this.mediaActionsForm.controls.action.value;
    const ids = this.selectedMediaItems.map((x) => x.id);
    // const names = this.mediaList.map((x) => x.name);
    const currentSelectedFolderId = this.getSelectedFolderId();

    switch (action) {
      case MediaBulkAction.DeleteMedia:
        this.toasterService.info('DELETING_MEDIA');
        this.bulkDelete({
          selectedMedia: [...this.selectedMediaItems],
          currentSelectedFolderId,
        });
        break;

      case MediaBulkAction.MoveSelected:
        // this.toasterService.info('MOVING_MEDIA_FOLDER');
        this.bulkMoveMediaFolder(ids);
        break;
      default:
        throw new Error(`Action not supported, ${action}`);
    }
    this.designageDataTable.selection.clear();
  }

  bulkActionClick(action: MediaBulkAction) {
    const ids = this.selectedMediaItems.map((x) => x.id);
    const currentSelectedFolderId = this.getSelectedFolderId();

    switch (action) {
      case MediaBulkAction.DeleteMedia:
        this.bulkDelete({
          selectedMedia: [...this.selectedMediaItems],
          currentSelectedFolderId,
        });
        break;

      case MediaBulkAction.MoveSelected:
        // this.toasterService.info('MOVING_MEDIA_FOLDER');
        this.bulkMoveMediaFolder(ids);
        break;
      default:
        throw new Error(`Action not supported, ${action}`);
    }
    this.designageDataTable.selection.clear();
  }

  //Delete all selected  media lists
  async bulkDelete({
    selectedMedia,
    currentSelectedFolderId,
  }: {
    selectedMedia: MediaForMediaListFragment[];
    currentSelectedFolderId: string | null;
  }) {
    await this.mediaService.openDeleteMediaDialog({
      mediaList: selectedMedia,
      mediaFolderId: currentSelectedFolderId,
    });
    this.designageDataTable.selection.clear();
  }

  bulkMoveMediaFolder(mediaIds: string[]) {
    const modal = this.modalService.open(MoveMediaFolderDialogComponent, {
      backdrop: 'static',
      size: 'xl',
    });

    modal.componentInstance.selectedMedia = this.selectedMediaItems.map(
      ({ name, publicId, type }) => ({
        name,
        publicId,
        type,
      })
    );
    console.log('data to modal:', modal.componentInstance.selectedMedia);
    console.log('full Data:', this.selectedMediaItems);

    modal.result.then(
      (folderId: string | null) => {
        lastValueFrom(
          this.mediaService.moveMediaToFolder({ folderId, mediaIds })
        );
      },
      () => {}
    );
  }

  async onSelectFolderId(folderId: string) {
    this.mediaStore.setSelectedFolderId(folderId);

    // update breadcrumb list
    this.folderBreadCrumbs = await this.getFolderBreadCrumbs(folderId);

    // this will refetch media list and refresh the UI
    // since the selectedFolderId is changed so will the
    // results of the refetched media list
    // this.getMedia();

    // get retrieve full path of folder by id
    if (!this.profileId) return;
    const folderWithFullPath =
      await this.folderService.getProfileFoldersWithFullPath(this.profileId);
    const selectedFolder = folderWithFullPath.find(
      (folder) => folder.id === folderId
    );
    this.selectedFolderFullPath =
      DEFAULT_FOLDER_PATH + (selectedFolder?.fullPath ?? '');
    // Check if folders if Folderexplorer should be visible by default
    this.folderExplorer.set(folderWithFullPath.length > 0);
  }

  async getFolderBreadCrumbs(folderId: string): Promise<IFolderBreadCrumb[]> {
    // initialize list with root breadcrumb
    let folderBreadCrumbs: IFolderBreadCrumb[] = [
      {
        id: null,
        name: this.translateService.instant('ROOT_FOLDER'),
      },
    ];

    // if folder id is root (folderId === null) return initial breadcrumb list
    if (folderId === null) return folderBreadCrumbs;

    const fullPathList = await this.folderService.getFullPathListById(folderId);
    if (!fullPathList) return folderBreadCrumbs;

    // concat breadcrumb list to the initial breadcrumb list
    folderBreadCrumbs = folderBreadCrumbs.concat(
      fullPathList.map(({ id, name }) => {
        return {
          id,
          name,
        };
      })
    );

    return folderBreadCrumbs;
  }
}
