@if(loading){
<app-loader [message]="loaderMessage" />
} @else {
<!-- header -->
<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    @if(showHeader()){
    <h4 class="flex-grow-1">
      {{ headerText() | translate }}
    </h4>
    } @if(showButtons && showCreativeEditor){
    <div class="mx-3" ngbDropdown container="body">
      <button
        type="button"
        class="btn btn-outline-primary me-3"
        style="padding: 0.6rem"
        ngbDropdownToggle
      >
        {{ 'CREATIVE_EDITOR' | translate }}
      </button>
      <div ngbDropdownMenu aria-labelledby="CreativeEditor">
        <button
          ngbDropdownItem
          (click)="createMedia('Design')"
          [ngbTooltip]="'CREATIVE_EDITOR_MODE_IMAGE_TT' | translate"
          placement="left"
        >
          {{ 'CREATIVE_EDITOR_MODE_IMAGE' | translate }}
        </button>
        <button
          ngbDropdownItem
          (click)="createMedia('Video')"
          [ngbTooltip]="'CREATIVE_EDITOR_MODE_VIDEO_TT' | translate"
          placement="left"
        >
          {{ 'CREATIVE_EDITOR_MODE_VIDEO' | translate }}
        </button>
      </div>
    </div>
    } @if(showButtons){ @if(!showCreativeEditor){
    <button
      type="button"
      (click)="openUploadWidget()"
      class="m-1 flex-grow-1 btn btn-sm btn-outline-primary btn-border-dashed"
    >
      {{ 'UPLOAD_MEDIA' | translate }}
    </button>
    }@else {
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="openUploadWidget()"
    >
      {{ 'UPLOAD_MEDIA' | translate }}</button
    >} }
  </div>

  <div class="my-2 d-flex justify-content-between align-items-center">
    <form [formGroup]="mediaSearchForm" class="flex-grow-1 d-flex">
      <app-search-input
        class="w-100"
        [debounceDelay]="500"
        [parentForm]="mediaSearchForm"
        (search)="applyFilter($event)"
        [enableGlobalSearch]="true"
        [globalSearch]="globalSearch()"
        (globalSearchChange)="mediaStore.setGlobalSearch($event)"
      ></app-search-input>
      <form [formGroup]="viewModeFormGroup">
        <div class="btn-group btn-group-toggle" name="viewMode">
          <input
            class="btn-check"
            type="radio"
            [(formControl)]="viewModeFormGroup.controls.isGridView"
            [value]="false"
            id="tableview"
            autocomplete="off"
          />
          <label class="btn btn-outline-light" for="tableview">
            <i class="ri-list-check"></i>
          </label>
          <input
            class="btn-check"
            type="radio"
            [(formControl)]="viewModeFormGroup.controls.isGridView"
            [value]="true"
            id="gridview"
            autocomplete="off"
          />
          <label class="btn btn-outline-light" for="gridview">
            <i class="ri-grid-fill"></i>
          </label>
        </div>
      </form>
    </form>
  </div>

  <!-- body -->
  <!-- media-list header -->
  <div
    class="pe-2 d-flex justify-content-between align-items-center overflow-hidden filter-bg"
  >
    <div class="folders-toggle" (click)="folderExplorer.set(!folderExplorer())">
      <h6 class="py-2 ps-3">
        <i class="ri-folder-fill me-2"></i>
        @if(showFolderExplorerText()){
        {{ folderExplorer() ? 'Hide folders' : 'Show folders' }}
        }
      </h6>
    </div>
    <!-- filter buttons -->
    <app-media-filter-button-group />
  </div>
  <as-split unit="pixel" gutterSize="0" useTransition="true">
    <as-split-area
      minSize="100"
      [size]="folderExplorerWidth()"
      [visible]="folderExplorer()"
      order="1"
    >
      <!-- folder explorer -->
      <app-folder-explorer
        [selectedFolderId]="getSelectedFolderId()"
        (selectFolderId)="onSelectFolderId($event)"
        (folderIds)="folderTreeIds = $event"
      ></app-folder-explorer>
    </as-split-area>
    <as-split-area size="*" class="table-area" order="2">
      <!-- media-list -->
      @if(!viewModeFormGroup.value.isGridView ){
      <designage-data-table
        configId="media-list"
        [columnSelector]="false"
        [showFilter]="false"
        [data]="filteredMedia()"
        [columns]="desColumns"
        [tableElementId]="'media-list'"
        [connectedDropList]="galleryView() ? sequenceIds : folderTreeIds()"
        [dropListData]="filteredMedia()"
        [dragDisabled]="false"
        [loading]="datatableLoading()"
        [selectedRows]="selectedMediaItems"
        (selectedRowsChange)="onSelectedChange($event)"
        [showBulkActions]="showBulkAction"
        [alwaysSort]="true"
        (rowClick)="
          galleryView() ? openPreviewDialog($event) : onRowClick($event)
        "
        [keepSelectedRowsOnClick]="isAssetSelection"
        [customComponent]="[thumbnail, fileSize, createdAt, dimensions]"
        [customDragClass]="'mediaDragDrop'"
        [showMultiSelect]="
          enableCheckbox && !(enableSlidePanel && isSlidePanelOpen())
        "
        [rowActiveInSlidingPanel]="selectedMediaId()"
      >
        <!-- Bulk Actions Template -->
        <ng-container ngProjectAs="bulkActions">
          @for(action of mediaListActions; track action){
          <button
            class="my-1 btn btn-sm mx-2"
            [ngClass]="bulkActionsUtil.getButtonClass(action).class"
            [ngbTooltip]="
              bulkActionsUtil.getButtonClass(action).toolTip | translate
            "
            (click)="bulkActionClick(action)"
          >
            {{ action | translate
            }}{{ bulkActionsUtil.getButtonClass(action).class }}</button
          >}
        </ng-container>
      </designage-data-table>

      <ng-template #thumbnail let-row>
        @switch (row.type) { @case ('IMAGE') {
        <cl-media
          ngbTooltip="{{ row.name }}"
          class="cl-media-image"
          [public-id]="row.publicId"
          mediaType="image"
          loading="lazy"
          width="120"
          height="120"
          resizeMethod="limit"
          quality="auto"
          fetch-format="webp"
        ></cl-media>
        } @case ('VIDEO') {
        <cl-media
          ngbTooltip="{{ row.name }}"
          class="cl-media-video"
          [public-id]="row.publicId"
          mediaType="videoPoster"
          loading="lazy"
          width="120"
          height="120"
          resizeMethod="limit"
          quality="auto"
          fetch-format="webp"
        ></cl-media>
        } @default {
        <img [src]="placeHolderImage" class="asset-img" />
        } }
      </ng-template>

      <ng-template #fileSize let-row>
        {{ row.metadata?.bytes | fileSize }}
      </ng-template>

      <ng-template #dimensions let-row>
        {{ row.metadata?.width }} x {{ row.metadata?.height }}
      </ng-template>

      <ng-template #dragTemplate let-row>
        <img *cdkDragPreview [src]="row.poster" [alt]="row.name" />
      </ng-template>

      <ng-template #createdAt let-row>
        <table-dateTime
          [data]="row.metadata?.createdAt"
          [tooltipPipeArgs]="'medium'"
        ></table-dateTime> </ng-template
      >} @else {
      <div
        id="media-gallery"
        class="py-3 media-grid-container overflow-auto gallery-bg h-100"
        cdkDropList
        [cdkDropListData]="filteredMedia()"
        [cdkDropListSortingDisabled]="true"
        [cdkDropListConnectedTo]="galleryView() ? sequenceIds : folderTreeIds()"
        (cdkDropListDropped)="dropTable($event)"
      >
        @for(mediaItem of filteredMedia(); track mediaItem.id){
        <div
          cdkDrag
          [cdkDragData]="mediaItem"
          [ngValue]="mediaItem"
          (cdkDragStarted)="dragStart($event)"
          class="draggable-content"
        >
          <div class="content-drag-placeholder" *cdkDragPlaceholder></div>
          <media-grid-card
            [mediaItem]="mediaItem"
            [selectedFolderId]="getSelectedFolderId()"
            (onMediaCardClick)="
              galleryView() ? openPreviewDialog($event) : onRowClick($event)
            "
          />
        </div>
        }
      </div>
      } @if(hasNoData() && !datatableLoading()){
      <hr />
      <h4 class="unavailable-message">
        {{ 'NO_CONTENT_FOUND' | translate }}
      </h4>
      }
    </as-split-area>
  </as-split>
</div>
}

<!-- Media Manage Panel -->
@if(enableSlidePanel){
<router-outlet />
}
