import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaSourceTypes } from '@desquare/enums';
import { CurrentUserService } from '@desquare/services';
import { MediaStore } from '@desquare/stores';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [NgClass, FormsModule, ReactiveFormsModule, TranslateModule],
  selector: 'app-media-filter-button-group',
  templateUrl: './media-filter-button-group.component.html',
  styleUrls: ['./media-filter-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFilterButtonGroupComponent {
  mediaStore = inject(MediaStore);
  currentUserService = inject(CurrentUserService);
  MediaSourceTypes = MediaSourceTypes;

  filterStates = this.mediaStore.mediaTypeFilter;

  get isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }
}
