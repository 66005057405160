import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FolderTreeComponent } from '../folder-tree/folder-tree.component';
import { TranslateModule } from '@ngx-translate/core';
import { FolderService } from '@desquare/services';

@Component({
  standalone: true,
  imports: [TranslateModule, FolderTreeComponent],
  selector: 'app-folder-explorer',
  providers: [FolderService],
  template: `
    <div class="h-100 py-3 container-fluid folder-explorer">
      <div class="d-flex justify-content-between">
        <!-- explorer header -->
        <div class="d-flex align-items-center text-truncate">
          <label>{{ 'FOLDER_EXPLORER_HEADER' | translate }}</label>
        </div>
      </div>

      <hr />

      <!-- explorer body -->
      <app-folder-tree
        [selectedFolderId]="selectedFolderId"
        (selectFolderId)="selectFolderId.emit($event)"
      ></app-folder-tree>
    </div>
  `,
})
export class FolderExplorerComponent {
  @Input() selectedFolderId!: string | null;
  @Output() selectFolderId = new EventEmitter<string | null>();
}
